// Define your custom mixins here.
// -----------------------------------------------------------------------------

$header-breakpoint: md;

@mixin make-h1 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h1-size;
  }
  font-size: $mobil-h1-size;
}

@mixin make-h2 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h2-size;
  }
  font-size: $mobil-h2-size;
}

@mixin make-h3 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h3-size;
  }
  font-size: $mobil-h3-size;
}

@mixin make-h4 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h4-size;
  }
  font-size: $mobil-h4-size;
}

@mixin make-h5 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h5-size;
  }
  font-size: $mobil-h5-size;
}

@mixin make-h6 {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $h6-size;
  }
  font-size: $mobil-h6-size;
}

@mixin make-body {
  @include media-breakpoint-up($header-breakpoint) {
    font-size: $body-size;
  }
  font-size: $mobil-body-size;
}

@mixin full-width-background($color) {
  position: relative;

  &:after,
  &:before {
    content: "";
    position: absolute;
    top: 0;
    z-index: -1;
    display: block;
    width: 100vw;
    height: 100%;
    background-color: $color;
  }

  &:after {
    left: 0;
  }

  &:before {
    right: 0;
  }
}
