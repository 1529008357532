// Variables
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and
// $modal-content-box-shadow-xs.

// To customize
// - Copy paste the variable from node_modules/bootstrap/scss/_variables.scss
// @see: https://github.com/twbs/bootstrap/blob/main/scss/_variables.scss
// - Change the value and remove the !default flag.
// e.g.
// $body-bg: #000;
// $body-color: $gray-100;

$primary-50: #f9e8e7;
$primary-100: #ecb8b5;
$primary-200: #e29691;
$primary-300: #d5665e;
$primary-400: #cd493f;
$primary-500: #c11b0f;
$primary-600: #b0190e;
$primary-700: #89130b;
$primary-800: #89130b;
$primary-900: #510b06;

$secondary-50: #e6efea;
$secondary-100: #b0ccbd;
$secondary-200: #8ab49d;
$secondary-300: #549270;
$secondary-400: #337d55;
$secondary-500: #005c2a;
$secondary-600: #005426;
$secondary-700: #00411e;
$secondary-800: #003317;
$secondary-900: #002712;

$grey-50: #efeff0;
$grey-100: #cecfcf;
$grey-200: #b6b7b8;
$grey-300: #949697;
$grey-400: #808283;
$grey-500: #606364;
$grey-600: #575a5b;
$grey-700: #444647;
$grey-800: #353637;
$grey-900: #282a2a;


$primary: $primary-500;
$secondary: $secondary-500;

$white: #fff;
$black: #2f2c26;

$fb-green-50: #e7f3ed;
$fb-green-500: #0d874e;

$fb-yellow-50: #fef8f0;
$fb-yellow-500: #f9ba67;

$fb-red-50: #fde9e7;
$fb-red-500: #e82011;

$green: $fb-green-500;
$yellow: $fb-yellow-500;
$red: $fb-red-500;

$body-bg: $white;
$custom-bootstrap-colors: (
  "primary": $primary,
  "secondary": $secondary,
  "success": $green,
  "info": $yellow,
  "warning": $yellow,
  "danger": $red,
);

$menu-breakpoint: lg;


$h1-size: px_to_rem(45px);
$h2-size: px_to_rem(36px);
$h3-size: px_to_rem(31px);
$h4-size: px_to_rem(27px);
$h5-size: px_to_rem(22px);
$h6-size: px_to_rem(18px);
$body-size: px_to_rem(17px);
$body-kicsi-size: px_to_rem(15px);

$mobil-h1-size: px_to_rem(26px);
$mobil-h2-size: px_to_rem(24px);
$mobil-h3-size: px_to_rem(22px);
$mobil-h4-size: px_to_rem(21px);
$mobil-h5-size: px_to_rem(20px);
$mobil-h6-size: px_to_rem(16px);
$mobil-body-size: px_to_rem(16px);

// responsive font size
// ez gyakorlatilag a legkisebb font méret, amelyet az RFS kiszámol
$rfs-base-value: 14px;
// ez az a breakpoint, amely felett a @include font-size(); -al megadott font méret már nem változik tovább
// a font méretet a szelektorra vonatkozó @include font-size(1.25rem); -val lehet megadni, méretet rem-ben, vagy px-ben definiálni.
$rfs-breakpoint: 1400px;
