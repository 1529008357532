@import "../../scss/init";


.page__footer {
  margin-top: px_to_rem(75px);
  background-color: $grey-900;
  color: $white;
}

.footer {
  text-align: center;
  margin-top: px_to_rem(50px);

  .h2 {
    color: $white;
  }

  .h6 {
    margin: px_to_rem(40px) 0;
  }

  .elerhetoseg-adat:not(:last-child) {
    margin-bottom: px_to_rem(32px);
  }
}

.footer--copyright {
  background-color: $white;
}

.page__content {
  position: relative;
}

.page__content {
  padding: 2rem 0;
}

header {
  .alert {
    margin-top: 2rem;
  }
}

html {
  scroll-padding-top: 300px;
}

$navbar-link-gap-desktop: 1em;
$navbar-link-gap-mobil: $navbar-link-gap-desktop;

$button-svg-color: $primary;

.navbar {
  .form-inline {
    .form-actions {
      display: none;
    }
  }
}

ul.navbar-nav {
  @include media-breakpoint-down($menu-breakpoint) {
    gap: $navbar-link-gap-mobil;
  }

  gap: $navbar-link-gap-desktop;

  .navbar-text {
    padding-top: 0;
    padding-bottom: 0;
  }

  .nav-link {
    font-weight: bold;
  }
}

.navbar {
  @include media-breakpoint-down($menu-breakpoint) {
    --bs-navbar-padding-x: 0 !important;
    --bs-navbar-padding-y: 0 !important;
    margin: 0;

    // container div amibe a navbar itemek vannak
    &>div {
      padding: 0;
    }
  }

  //Hamburger gombok start
  .navbar-toggler {
    @include media-breakpoint-down($menu-breakpoint) {
      align-self: stretch;
    }


    aspect-ratio: 1;
    border: none;
    border-radius: 0;
    background: none;
    font-weight: 700;
    font-size: .85em;
    transition: display slide-in .2s;

    &:focus {
      box-shadow: none;
    }

    * {
      transition: all ease-in .2s;
    }

    .icon-menu,
    .icon-close {
      fill: $button-svg-color;
    }
  }
}

.navbar-toggler[aria-expanded="true"] {
  .toggler-icon.open {
    display: block;
    animation: fadeIn .5s;
  }

  .toggler-icon.closed {
    display: none;
  }
}

.navbar-toggler[aria-expanded="false"] {
  .toggler-icon.open {
    display: none;
  }

  .toggler-icon.closed {
    display: block;
    animation: fadeIn .5s;
  }
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

//gombok vége

.navbar-collapse {
  @include media-breakpoint-down($menu-breakpoint) {
    background-color: $secondary;
    padding: $navbar-link-gap-mobil 0;
    
    a {
      color: $white;

      &:hover {
        color: $grey-300;
      }
    }
  }

  &>div {
    gap: $navbar-link-gap-desktop;
  }
}

.hero {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: px_to_rem(400px);
  padding: 0 1rem;
  background-image: url("/src/assets/images/hero-background.jpg");
  background-position: center;
  background-size: cover;
  color: $white;
  font-weight: bold;
  text-align: center;

  .hero-text {
    margin-bottom: px_to_rem(90px);
    font-family: $headings-font-family;

    * {
      margin-bottom: px_to_rem(16px);
    }

    .udvozlet {
      @include make-h4;
    }

    .pro-media {
      @include make-h1;
    }

    .oldalan {
      @include make-h4;
    }
  }

  .hero-image {
    position: absolute;
    top: calc(100% - 140px);
    max-width: 600px;
  }
}

img {
  max-width: 100%;
  height: auto;
}

.primary {
  color: $primary;
  font-weight: bold;
}

.rolunk {
  margin-top: 12rem;
  padding: px_to_rem(50px) 0;
  text-align: center;
  background: $grey-50;

  .tagok-row {
    justify-content: center;
  }

  .tag-image img {
    max-width: 196px;
    margin-top: px_to_rem(12px);
    margin-bottom: px_to_rem(12px);
  }

  .tag-nev {
    font-weight: bold;
  }

  .email,
  .tel {
    font-size: .8rem;
  }

  .rolunk-text {
    margin-top: px_to_rem(45px);
  }

  h1 {
    color: $grey-900;
    @include make-h2;
  }

  .tagok {
    .primary {
      margin: px_to_rem(45px) 0;
      font-weight: bold;
      @include make-h6;
      font-family: $headings-font-family;
    }
  }
}

.tevekenysegunk {
  margin: px_to_rem(90px) 0;

  p:last-child {
    margin-top: 0;
  }

  h2 {
    text-align: center;
    margin-bottom: px_to_rem(40px);
    color: $grey-900;
  }

  .h4 {
    @include media-breakpoint-up(md) {
      margin: px_to_rem(36px) 0 px_to_rem(32px);
    }

    margin: px_to_rem(32px) 0 px_to_rem(24px);
  }
}

.tekintse-meg-oldalainkat {
  .buttons {
    @include media-breakpoint-up(md) {
      flex-direction: row;
    }

    flex-direction: column;
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 1rem;
    margin-top: 2rem;
    flex-wrap:wrap;

    a {
      display: block;
      width: fit-content;
    }
  }
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.social-container {
  margin-top: 1rem;

  a {
    padding: .75rem;
    background: $secondary;
    border-radius: 50%;
    transition: background .2s ease-in-out;

    &:hover {
      background: $secondary-800;
    }
  }

  .svg-icon.link {
    fill: $white;
    width: px_to_rem(47px);
    height: px_to_rem(47px);

    &:hover {
      fill: $white;
    }
  }
}

.block--copyrightfooter {
  @include font-size(1rem);
  width: 100%;
}

.copyright-footer {
  background-color: $white;
  color: $grey-900;
  padding: .75rem 0;

  .svg-egm-logo {
    max-width: 140px;
    fill: $primary-500;
  }

  .egm {
    display: inline-block;
    text-indent: -10000px;
  }
}
