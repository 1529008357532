$btn-padding-y: 10px;
$btn-padding-x: 25px;
$btn-font-size: 1.4rem;

$btn-padding-y-sm: 5px;
$btn-padding-x-sm: 15px;
$btn-font-size-sm: 1.1rem;

$btn-padding-y-lg: 15px;
$btn-padding-x-lg: 30px;
$btn-font-size-lg: 1.8rem;

$btn-link-color: $white;

$btn-font-weight: 700;

$btn-border-radius: 50px;
$btn-border-radius-sm: 50px;
$btn-border-radius-lg: 50px;

$headings-color: $primary;
$headings-font-weight: 700;

$font-family-base: Open Sans, sans-serif;
$headings-font-family: Montserrat, sans-serif;

$font-weight-normal: 400;
$font-weight-bold: 700;
$body-bg: $white;
$body-color: $grey-900;

$navbar-light-color: $grey-900;
$navbar-light-hover-color: $secondary;
